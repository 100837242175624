<template>
  <b-container class="mt-sm-5 mt-3">
    <b-row>
      <b-col sm="12">
        <div class="box">
          <div>
            <h2>
              <span>{{ $t("Náš") }}</span>
              <span class="w-color-secondary z-fancy-underline fix">
                {{ $t("príbeh!") }}<svg-fancy-underline-icon />
                <img
                  src="/topCourses.png"
                  class="a-small-logo"
                  id="srd"
                  alt=""
                />
              </span>
            </h2>
            <p>
              {{ $t("about-us-story-1") }}
            </p>
            <p>
              {{ $t("about-us-story-2") }}
            </p>
          </div>
          <div class="picture">
            <img src="/group.png" alt="" />
          </div>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  data() {
    return {
      text2: import("/assets/icons/text2.svg"),
    };
  },
  components: {
    "svg-fancy-underline-icon": () =>
      import("/assets/icons/fancyUnderline.svg?inline"),
  },
};
</script>

<style lang="scss" scoped>
p,
a {
  margin: 1rem;
  line-height: 28px;
  font-size: 15px;
  font-weight: 100;
}
h2 {
  margin-left: 1rem;
}
.box {
  p {
    min-width: 30vw;
  }
  display: flex;
  flex-direction: row;
  align-items: center;
}
.centered {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  margin: 1rem;
}
.picture {
  max-width: 100%;
  img {
    min-width: 100%;
    max-width: 50vw;
  }
}
@media only screen and (min-width: 1300px) {
  .picture {
    max-width: 70%;
  }
}
@media only screen and (max-width: 991px) {
  .centered {
    justify-content: center;
    img {
      justify-items: center;
      margin-left: 7rem;
    }
  }
  h2 {
    margin-left: 0rem;
  }
  .box {
    flex-direction: column;
    p {
      justify-items: center;
      width: 100%;
      margin: auto;
    }
    .picture {
      margin-top: 2rem;
      width: 100%;
      img {
        width: 100%;
      }
    }
  }
}
</style>
